import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header : 'id ',
		Footer : 'id',
		accessor: 'idClientes',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'nombre',
		Footer : 'nombre',
		accessor: 'nombre',
		Filter: ColumnFilter,
	},
	{
		Header : 'ruc',
		Footer : 'ruc',
		accessor: 'ruc',
		Filter: ColumnFilter,
	},
	
	{
		Header : 'razonsocial',
		Footer : 'razonsocial',
		accessor: 'razonsocial',
		Filter: ColumnFilter,
	},
	{
		Header : 'telefono',
		Footer : 'telefono',
		accessor: 'telefono',
		Filter: ColumnFilter,
	},
]
export const COLUMNSCOMPRA = [
	{
		Header: () => (
			<div
			  style={{
				textAlign:"Center"
			  }}
			>Factura</div>),
		accessor: 'idFactura',
		Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
		Filter: ColumnFilter,
	},
	
	{
		Header: () => (
			<div
			  style={{
				textAlign:"Center"
			  }}
			>Cant.</div>),
		accessor: 'cantidad',
		Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
		Filter: ColumnFilter,
	},
	{
		Header: () => (
			<div
			  style={{
				textAlign:"Center"
			  }}
			>Artcod.</div>),
		
		accessor: 'artcod',
		Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
		Filter: ColumnFilter,
	},
	{
		Header: () => (
			<div
			  style={{
				textAlign:"Center"
			  }}
			>Producto</div>),
		accessor: 'producto',
		Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
		Filter: ColumnFilter,
	},
	
	{
		Header: () => (
			<div
			  style={{
				textAlign:"Center"
			  }}
			>Precio</div>),
		
		accessor: 'precio',
		Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
		Filter: ColumnFilter,
	},
	{
		Header: () => (
			<div
			  style={{
				textAlign:"Center"
			  }}
			>Total</div>),
		
		accessor: 'total',
		Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
		Filter: ColumnFilter,
	},
]
export const COLUMNS2 = [
	{
		Header : 'id',
		Footer : 'id',
		accessor: 'idFactura',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Cod Suc',
		Footer : 'Cod Suc',
		accessor: 'pf1',
		Filter: ColumnFilter,
	},
	{
		Header : 'Cod PDE',
		Footer : 'Nº de Factura',
		accessor: 'pf2',
		Filter: ColumnFilter,
	},

	{
		Header : 'Nº de Factura',
		Footer : 'Nº de Factura',
		accessor: 'numerofactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Razon Social',
		Footer : 'Razon Social',
		accessor: 'razonsocial',
		Filter: ColumnFilter,
	},
	{
		Header : 'Ruc',
		Footer : 'Ruc',
		accessor: 'ruc',
		Filter: ColumnFilter,
	},
	{
		Header : 'fecha Emision',
		Footer : 'fechaEmision',
		accessor: 'fechafactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Tipo Factura',
		Footer : 'Formadepago',
		accessor: 'tipoFactura',
		Filter: ColumnFilter,
	},
	
	{
		Header : 'Total',
		Footer : 'Total',
		accessor: 'totalFactura',
		Filter: ColumnFilter,
	},
]
export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]