import React, {
  useReducer,
  useEffect, useState, useCallback
} from "react";
import { useLocation, useNavigate,useParams} from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup
} from "react-bootstrap";
import ModalAgregarClientes from "../../sis/ModalFacturacion/ModalAgregarCliente"
import { getClienteById, deleteClient, saveCliente } from "../../../../Api/cliente"
import { getFacturaByClienteId, getFacturaCredByClienteId } from "../../../../Api/facturacion"
import { TailSpin } from 'react-loader-spinner'
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { JsonToExcel } from "react-json-to-excel";
const showReducer = (curShow, state) => {


  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
      };
    case "SHOW2":
      return {
        isShow2: true,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
  }
};
const FichaCliente = () => {
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [items3, setItems3] = useState([]);

  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,

  });
  const Editar = () => {

    dispatchReducer({
      type: "SHOW",
    });
  };
  const onReturnValue = async (data) => {
    try {
      Swal.fire({
        title: 'Cargando',
        html: 'Cargando  datos',// add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,

      });
      const result = await saveCliente({ ...data, isNew: false, idCliente: id });

      if (result) {
        if (result) {
          assigmentValue()
          Swal.close();
          Swal.fire({
            title: "Cliente Cargado",
            html: `Se cargo correctalmente el cliente`,
            icon: "success",
            confirmButtonColor: 'black'
          });
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };


  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const Eliminar = () => {

    try {
      Swal.fire({
        title: 'Estas seguro/a de Eliminar?',
        text: "No es Reversible!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'black',
        cancelButtonColor: 'red',
        confirmButtonText: 'Si! Eliominar'
      }).then((result) => {
        if (result.isConfirmed) {

          deleteClient(id)

          Swal.fire({
            title: 'Eliminado',
            text: "Cliente Eliminado",
            icon: 'warning',
            confirmButtonColor: 'black',
          })
          navigate("/Clientes")

        }
      })

    }
    catch (err) {
      console.log(err);
    }
  }
  const assigmentValue = useCallback(async () => {
    try {
      const result = await getClienteById(id);
      const result2 = await getFacturaByClienteId(id);
      const result3 = await getFacturaCredByClienteId(id);
      setdata(result3)
      setdata2(result2)

      setItems(result);
      setItems2(result2);
      setItems3(result3);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  
  return (
    <>
      <TailSpin
        height="80"
        width="80"
        color="Black"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={loading}
      />
      {items.map((item, index2) => {
        return (
          <div key={index2}>
            <div className="row">
              <div className="col-xl-4 col-xxl-4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">{item.nombre}</h4><ButtonGroup aria-label="Basic example">
                          <Button onClick={Editar} variant="primary">Editar</Button>
                          <Button onClick={Eliminar} variant="danger">Eliminar</Button>
                        </ButtonGroup>

                      </div>
                      <div className="card-body p-4">
                        <span>Razon Social :  {item.razonsocial}</span>
                        <br></br>
                        <span>
                          Ruc : {item.ruc}
                        </span>
                        <br></br>
                        <span>Telefono : {item.telefono} </span>
                        <br></br>
                        <span>Correo : {item.correo}</span>
                        <br></br>
                        <span>Ciudad : {item.Ciudad}</span>
                        <br></br>
                        <span>Direccion : {item.direccion}</span>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-xl-8 col-xxl-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Historial Facturas a Credito</h4>
                        <JsonToExcel
                          title="Descargar Como Excel"
                          data={data}
                          fileName="Historial de Facturas credito"
                          btnClassName="custom-classname rounded"
                        />
                      </div>

                      <div className="card-body p-5">
                        <h2>Mas Recientes</h2>
                        <Table responsive className="primary-table-bordered">
                          <thead className="thead-primary">
                            <tr>
                              <th style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                              }} scope="col">Num Factura</th>
                              <th style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                              }} scope="col">Fecha de emision</th>
                              <th scope="col">Monto</th>
                              <th scope="col">Vencimiento</th>
                              <th style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                              }} scope="col">Ver factura</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items3.slice(0, 5).map((items3, index) => {
                              return (

                                <tr key={index}>
                                  <td>{items3.pf1}-{items3.pf2}-{items3.numerofactura}</td>
                                  <td>{items3.fechafactura}</td>
                                  <td>{items3.totalFactura}</td>
                                  <td>{items3.fechapagovencimiento}</td>
                                  <td><Link to={`/FichaFactura/${items3.idFactura}`}>Ver Factura </Link></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-xl-12 col-xxl-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Historial  Facturas </h4>
                        <JsonToExcel
                          title="Descargar Como Excel"
                          data={data2}
                          fileName="Historial de Facturas "
                          btnClassName="custom-classname rounded"
                        />
                      </div>
                      <div className="card-body p-4">
                      <h2>Mas Recientes</h2>
                        <Table responsive className="primary-table-bordered">
                          <thead className="thead-primary">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Num Factura</th>
                              <th scope="col">Monto</th>
                              <th scope="col">Fecha de emision</th>
                              <th scope="col">Numero de comprobante</th>
                              <th scope="col">Forma de pago</th>
                              <th scope="col">Tipo</th>
                              <th style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                              }} scope="col">Ver factura</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items2.slice(0,10).map((items2, index3) => (
                              <tr key={index3}>
                                <td>{items2.idFactura}</td>
                                <td>{items2.pf1}-{items2.pf2}-{items2.numerofactura}</td>
                                <td>{items2.totalFactura}</td>
                                <td>{items2.fechafactura}</td>
                                <td>{items2.numerodecomprobante}</td>
                                <td>{items2.formadepago}</td>
                                <td>{items2.tipoFactura}</td>
                                <td><Link to={`/FichaFactura/${items2.idFactura}`}>Ver Factura </Link></td>
                              </tr>
                            ))}

                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        );
      })}



      {reducer.isShow && (
        <ModalAgregarClientes
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          Editdata={items}
          onReturnValue={onReturnValue}
        />
      )}

    </>
  )
}
export default FichaCliente;