import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet,Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import ScrollToTop from "./ScrollToTop";
import { ThemeContext } from "../context/ThemeContext";
import OTimbrado from "./pages/opciones/Otimbrado";
import Ocostos from "./pages/opciones/Ocostos";
import Oformasdepago from "./pages/opciones/Oformasdepago";
import Osucursal from "./pages/opciones/Osucursal";
import Omoneda from "./pages/opciones/Omoneda";
import FacturaContado from "./pages/FacturaContado";
import Depositado from "./pages/facturascontado/Depositado";
import Nodepositado from "./pages/facturascontado/Nodepositado";
import FacturaCredito from "./pages/FacturaCredito";
import Reporte from "./pages/Reportes";
import Opuntodeventa from "./pages/opciones/Opuntodeventa";
import Clientes from "./pages/facturacion/Clientes";
import Facturas from "./pages/facturacion/Facturas";
import Listadeventas from "./pages/facturacion/Listadeventas.js";
import FichaCliente from "./pages/facturacion/Fichas/FichaCliente";
import FichaFactura from "./pages/facturacion/Fichas/FichaFactura";
import Vencimiento from "./pages/facturascredito/Vencimientos";
import Cobranzas from "./pages/facturascredito/Cobranza";
import Generarfactura from "./pages/Generarfactura.js";
import AppLogout from "./AppLogout";
/*proveedores*/
import Proveedores from "./pages/compras/Proveedores";
import Compras from "./pages/compras/Compras";
import Listadecompras from "./pages/compras/Listadecompras";
import Reportecompras from "./pages/compras/Reportecompras";
import GenerarCompra from "./pages/compras/GenerarCompra";
import FichaFacturaCompra from "./pages/compras/FichaFacturaCompra.js";
const Markup = () => {
  const allRoutes = [
    // rutas

    { url: "Opcionesdetimbrado", component: <AppLogout><OTimbrado /></AppLogout>  },
    { url: "Opcionesdecosto", component: <AppLogout><Ocostos /> </AppLogout> },
    { url: "Opcionesformadepago", component: <AppLogout><Oformasdepago /></AppLogout>  },
    { url: "Opcionesdemoneda", component:  <AppLogout><Omoneda /></AppLogout> },
    { url: "FacturaContado", component:  <AppLogout><FacturaContado /></AppLogout> },
    { url: "depositado", component:  <AppLogout><Depositado /></AppLogout> },
    { url: "nodepositado", component:  <AppLogout><Nodepositado /></AppLogout> },
    { url: "FacturaCredito", component:  <AppLogout><FacturaCredito /></AppLogout> },
    { url: "Reporte", component:  <AppLogout><Reporte /></AppLogout> },
    { url: "Opcionesdesucursal", component:  <AppLogout><Osucursal /></AppLogout> },
    { url: "Opcionesodv", component:  <AppLogout><Opuntodeventa /></AppLogout> },
    { url: "Clientes", component:  <AppLogout><Clientes /></AppLogout> },
    { url: "Facturas", component: <AppLogout> <Facturas /> </AppLogout>},
    { url: "Detalles", component: <AppLogout> <Listadeventas /> </AppLogout>},
    { url: "FichaCliente/:id", component: <AppLogout><FichaCliente /></AppLogout>  },
    { url: "FichaFactura/:id", component: <AppLogout><FichaFactura /> </AppLogout> },
    { url: "cobranza", component:  <AppLogout><Cobranzas /> </AppLogout>},
    { url: "vencimientos", component:  <AppLogout><Vencimiento /></AppLogout> },
    { url: "generarfactura", component:  <AppLogout><Generarfactura /></AppLogout> },
    /*proveedores*/
    { url: "proveedores", component:  <AppLogout><Proveedores /></AppLogout> },
    { url: "compras", component:  <AppLogout><Compras /></AppLogout> },
    { url: "listadecompras", component:  <AppLogout><Listadecompras /></AppLogout> },
    { url: "reportecompras", component:  <AppLogout><Reportecompras /></AppLogout> },
    { url: "generarcompra", component:  <AppLogout><GenerarCompra /></AppLogout> },
    { url: "FichaFacturaCompra/:id", component:  <AppLogout><FichaFacturaCompra /></AppLogout> },

  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
    
      <Routes>
        <Route element={<Layout7 />}>
          {allRoutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`/${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
                    path="*"
                    element={ <Navigate to="/Facturas" /> }
                />
      </Routes>
     <ScrollToTop></ScrollToTop>
   
    </>
  );
};

function Layout7() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className="show"
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height + 20 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Markup;
